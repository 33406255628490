/** @jsx jsx */
// @ts-ignore
import { useCallback } from 'react'
import { jsx, Grid, Box } from 'theme-ui'
import {
  CollectionTemplateType,
  Maybe,
  ProductItemComponentType,
  ProductVariantType,
} from '~/@types/models'
import CollectionLayout from '~/components/layout/collection-layout'
import ProductItem from '~/components/product-item'
import CollectionProducts from '~/containers/collection-products'
import { isCircularRug, isRunnerRug } from '~/utils/product-variant'

import { buildLastChanceProducts } from '../../gatsby/util'

type Props = CollectionTemplateType

const getProductVariant = (product: ProductItemComponentType): Maybe<ProductVariantType> => {
  // todo - refactor
  if (product.isRunnerOrCircularRug) {
    return product.variants.find(variant => {
      return isRunnerRug(variant) || isCircularRug(variant)
    })
  }

  return null
}

const Collection = ({ pageContext: { data } }: Props) => {
  // in-stock pages have a different layout
  const gridVariant =
    data.handle.indexOf('in-stock') > -1 ? 'layout.template-inStock' : 'layout.template-default'

  return (
    <CollectionLayout
      title={data.title}
      seoTitle={data.seo?.title || data.title}
      heading={data.metafields?.customHeading}
      description={data.seo?.description || data.description}
      ankleContent={data.metafields?.ankleContent}
      handle={data.handle}
      heroHeader={{
        isExternal: true,
        ...(data.imageResponsive && {
          imageSet: data.imageResponsive,
        }),
      }}
      catalog={data.catalog}
      noIndex={data.metafields?.noindex}
    >
      <CollectionProducts
        data-template="collection"
        initialData={data.products}
        collectionHandle={data.handle}
        pagination={data.pagination}
        filters={data.filters}
        data={data}
        gridVariant={gridVariant}
      />
    </CollectionLayout>
  )
}

export default Collection
